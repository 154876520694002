import React, { useMemo } from 'react'
import { observer } from 'mobx-react'

import { classNames } from 'utils/react'

import { ArticleAccessor, ArticleLocaleAccessor, ShortUrlAccessor } from 'accessors'

import state from 'state'

import {
  useTranslate, useLocalStore, useCallback, useObserver,
} from 'hooks'

import SEO from 'components/shared/seo'
import { FaPlus, FaToggleOn } from 'components/shared/icons'

import Article from './dashboard/article'

import './dashboard.scss'

const block = 'page-dashboard'
const cx = classNames(block)

const useSearch = (placeholder: string) => {
  const search = useLocalStore(() => ({
    value: '',
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      search.value = e.target.value
    },
    placeholder,
  }))

  return search
}

const useHidePublished = () => {
  const hidePublished = useLocalStore(() => ({
    active: localStorage.getItem('hide-published') === 'true',
    toggle: () => {
      hidePublished.active = !hidePublished.active
      localStorage.setItem('hide-published', `${hidePublished.active}`)
    },
  }))

  return hidePublished
}

const useSort = () => {
  const sort = useLocalStore(() => ({
    active: localStorage.getItem('sort') === 'true',
    toggle: () => {
      sort.active = !sort.active
      localStorage.setItem('sort', `${sort.active}`)
    },
  }))

  return sort
}

const useNewArticleCallback = (strings : { language: any, title: any }) => useCallback(async () => {
  const language = await state.prompt.select({
    ...strings.language,
    options: [{
      label: 'Français',
      value: 'fr',
    }, {
      label: 'English',
      value: 'en',
    }],
  })

  if (!language) {
    return
  }

  const title = await state.prompt.ask(strings.title)

  if (!title) {
    return
  }

  // Create the corresponding locale
  const locale : { _id: string } = await ArticleLocaleAccessor.mutate
    .create
    .withRecord({
      title,
    })
    .select({
      _id: true,
    }) as any

  const { key } : { key: string } = await ShortUrlAccessor.query
    .get
    .where({
      type: 'article',
    })
    .select({
      key: true,
    }) as any

  // Create the new article
  await ArticleAccessor.mutate
    .create
    .withRecord({
      key: `${key}`,
      content: {
        locale: {
          [language]: locale._id,
        },
        publication: {},
      },
    })
}, [])

const useSortArticle = (sortByLikes : boolean) => useCallback((a, b) => {
  if (sortByLikes) {
    if (Number(a.likes) > Number(b.likes)) {
      return -1
    }

    if (Number(b.likes) > Number(a.likes)) {
      return 1
    }
  }

  // Get latest locale for each article
  const aLocales = a.content.locale.map.values.map((l: any) => new Date(l.updated).valueOf())
  const bLocales = b.content.locale.map.values.map((l: any) => new Date(l.updated).valueOf())

  const aMax = Math.max(...aLocales)
  const bMax = Math.max(...bLocales)

  return bMax - aMax
}, [sortByLikes])

const useFilterSearchedArticle = (search: string) => useCallback((article) => {
  if (!search) {
    return true
  }

  const titles = article.content.locale.map.values.map((l : any) => l.title)
  const previews = article.content.locale.map.values.map((l : any) => l.preview)

  const terms = `${titles.join(' ')} ${previews.join(' ')}`.toLowerCase()

  return terms.includes(search.toLowerCase())
}, [search])

const useFilterPublishedArticle = (hidePublished: boolean) => useCallback((article) => {
  if (!hidePublished) {
    return true
  }

  const allPublished = article.content.locale.map.keys
    .reduce(
      (all: boolean, locale: any) => all
        && article.content.publication
        && article.content.publication.map.keys.includes(locale),
      true,
    )

  return !allPublished
}, [hidePublished])

const useRenderArticle = (hidePublished: boolean) => useCallback((article) => (
  <Article key={article._id} hidePublished={hidePublished} {...article} />
), [hidePublished])

const useFilteredArticles = (search: string, hidePublished: boolean, sortByLikes: boolean) => {
  const sortArticles = useSortArticle(sortByLikes)

  const filterSearchedArticle = useFilterSearchedArticle(search)

  const filterPublishedArticle = useFilterPublishedArticle(hidePublished)

  const renderArticle = useRenderArticle(hidePublished)

  const observedArticles = useObserver(() => state.articles.list)

  return useMemo(
    () => observedArticles
      .slice()
      // Sort articles by updated date
      .sort(sortArticles)
      // Filter out based on searched keyword
      .filter(filterSearchedArticle)
      // Filter out all-published article based on hidePublished state
      .filter(filterPublishedArticle)
      // Render each article
      .map(renderArticle), [observedArticles, search, hidePublished, sortByLikes],
  )
}

const useAccountName = () => useObserver(() => state.session.account && state.session.account.name)

const PageDashboard = () => {
  const Strings = useTranslate('pages.connected.dashboard')

  const search = useSearch(Strings.search.placeholder)

  const hidePublished = useHidePublished()
  const sort = useSort()

  const onNewArticle = useNewArticleCallback(Strings.new)

  const filteredArticles = useFilteredArticles(search.value, hidePublished.active, sort.active)

  const accountName = useAccountName()

  return (
    <div className={cx(block)}>
      <SEO title={Strings.title} />

      <div className={cx('__hello')}>
        {Strings.hello}
        {' '}
        {accountName}
      </div>

      <button type="button" className={cx('__new-article')} onClick={onNewArticle}>
        <FaPlus className={cx('__new-icon')} />
        {Strings.new.label}
      </button>

      <div className={cx('__controls')}>
        <input className={cx('__search')} {...search} />

        <div className={cx('__filters')}>
          <button type="button" onClick={hidePublished.toggle} className={cx('__hide-published')}>
            <FaToggleOn
              className={cx('__hide-published-icon', { '__hide-published-icon--active': hidePublished.active })}
            />
            {Strings.hidePublished}
          </button>
          <button type="button" onClick={sort.toggle} className={cx('__sort')}>
            <FaToggleOn
              className={cx('__sort-icon', { '__sort-icon--active': sort.active })}
            />
            {Strings.sort.by}
            &nbsp;
            <span className={cx('__sort-option', { '__sort-option--active': !sort.active })}>
              {Strings.sort.date}
            </span>
            /
            <span className={cx('__sort-option', { '__sort-option--active': sort.active })}>
              {Strings.sort.likes}
            </span>
          </button>
        </div>
      </div>

      <div className={cx('__articles')}>
        {filteredArticles}
      </div>
    </div>
  )
}

export default observer(PageDashboard)
