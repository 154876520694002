import React from 'react'

import LayoutConnected from 'components/layout/connected'
import PageDashboard from 'components/pages/connected/dashboard'

export default (props : any) => (
  <LayoutConnected>
    <PageDashboard {...props} />
  </LayoutConnected>
)
