import React from 'react'

import Configuration from 'configuration'

import { classNames } from 'utils/react'
import { stopPropagation } from 'utils/misc'

import { useTranslate } from 'hooks'

import { FaEye, FaEdit, FaHeart } from 'components/shared/icons'
import { Link } from 'components/shared/semantics'

import './article.scss'

const block = 'article'
const cx = classNames(block)

const Article = ({
  _id, likes, content: { locale: { map: { values, keys } }, publication }, hidePublished = false,
} : {
  _id: string
  likes: number
  content: {
    locale: {
      map: {
        values: {
          title: string
          preview: string
        }[]
        keys: string[]
      }
    }
    publication: {
      map: {
        keys: string[]
      }
    }
  }
  hidePublished?: boolean
}) => {
  const Strings = useTranslate('pages.connected.dashboard.article')

  return (
    <div className={cx(block)}>
      {
        keys.map((key, i) => {
          const locale = values[i]

          const published = !!publication && publication.map.keys.includes(key)

          if (published && hidePublished) {
            return null
          }

          return (
            <Link
              key={key}
              className={cx('__locale', { '__locale--published': published })}
              to={`/edit?article=${_id}&locale=${key}`}
            >
              <section
                className={cx('__status', {
                  '__status--published': published,
                })}
              >
                {Strings.published[published]}
              </section>
              <section className={cx('__presentation')}>
                <h2 className={cx('__title')}>{locale.title}</h2>
                <p className={cx('__preview')}>{locale.preview}</p>
              </section>
              <section className={cx('__likes')}>
                {
                  !i && (
                    <>
                      {likes || 0}
                      <FaHeart className={cx('__likes-icon')} />
                    </>
                  )
                }
              </section>
              <section className={cx('__language')}>
                {key}
              </section>
              <section className={cx('__actions')}>
                <Link className={cx('__action')} to={`/edit?article=${_id}&locale=${key}`}>
                  <FaEdit className={cx('__action-icon')} />
                  <span className={cx('__action-label')}>
                    {Strings.actions.edit}
                  </span>
                </Link>
                <a
                  onClick={stopPropagation}
                  className={cx('__action')}
                  href={`${Configuration.public}/${key}/preview?article=${_id}&locale=${key}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FaEye className={cx('__action-icon')} />
                  <span className={cx('__action-label')}>
                    {Strings.actions.preview}
                  </span>
                </a>
              </section>
            </Link>
          )
        })
      }
    </div>
  )
}

export default Article
